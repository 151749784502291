const theme = [
  {
    "id": "001",
    "name": "Standard",
    "backgroundColor": "#96c6fa",
    "primaryColor": "#0c3b5e",
    "secondaryColor": "#ebf3fc",
    "accentColor1": "#0f6cbd",
  },
  {
    "id": "002",
    "name": "Beach",
    "backgroundColor": "#F9E4B7",
    "primaryColor": "#2E8BC0",
    "secondaryColor": "#56C9D8",
    "accentColor1": "#82E0AA",
  },
  {
    "id": "003",
    "name": "Forest",
    "backgroundColor": "#B5EAAA",
    "primaryColor": "#4f3e28",
    "secondaryColor": "#437C43",
    "accentColor1": "#5FAD56",
  },
  {
    "id": "004",
    "name": "High-Tech",
    "backgroundColor": "#EEEEEE",
    "primaryColor": "#8E8E8E",
    "secondaryColor": "#00b3b3",
    "accentColor1": "#0080ff",
  },
  {
    "id": "005",
    "name": "Halloween",
    "backgroundColor": "#1F1F1F",
    "primaryColor": "#FFA500",
    "secondaryColor": "#8A2BE2",
    "accentColor1": "#FF6347",
  },
  {
    "id": "006",
    "name": "Winter",
    "backgroundColor": "#D3E5E8",
    "primaryColor": "#0f6cbd",
    "secondaryColor": "#0f6cbd",
    "accentColor1": "#2992e8",
  },
  {
    "id": "007",
    "name": "Christmas",
    "backgroundColor": "#eaeaea",
    "primaryColor": "#C8102E",
    "secondaryColor": "#034638",
    "accentColor1": "#D4AF37",
  },
  {
    "id": "008",
    "name": "Ocean",
    "backgroundColor": "#81D4FA",
    "primaryColor": "#01579B",
    "secondaryColor": "#00ACC1",
    "accentColor1": "#0288D1",
  },
  {
    "id": "009",
    "name": "Bee Garden",
    "backgroundColor": "#F0F4C3",
    "primaryColor": "#FFC107",
    "secondaryColor": "#8BC34A",
    "accentColor1": "#795548",
  },
  {
    "id": "010",
    "name": "Sunset",
    "backgroundColor": "#faa06b",
    "primaryColor": "#fde300",
    "secondaryColor": "#f600f6",
    "accentColor1": "#5b5fc7",
  },
  {
    "id": "011",
    "name": "Astronomy",
    "backgroundColor": "#212121",
    "primaryColor": "#03DAC5",
    "secondaryColor": "#FFD54F",
    "accentColor1": "#FF4081",
  },
  {
    "id": "012",
    "name": "Valentine's Day",
    "backgroundColor": "#FFC0CB",
    "primaryColor": "#FF69B4",
    "secondaryColor": "#FFB6C1",
    "accentColor1": "#FF1493",
  },
  {
    "id": "013",
    "name": "Lavender",
    "backgroundColor": "#E6E6FA",
    "primaryColor": "#6A5ACD",
    "secondaryColor": "#8A2BE2",
    "accentColor1": "#9370DB",
  },
  {
    "id": "014",
    "name": "Dawn",
    "backgroundColor": "#FFD180",
    "primaryColor": "#FF5722",
    "secondaryColor": "#d53300",
    "accentColor1": "#62abf5",
  },
  {
    "id": "015",
    "name": "Zoo",
    "backgroundColor": "#C8E6C9",
    "primaryColor": "#4CAF50",
    "secondaryColor": "#388E3C",
    "accentColor1": "#FFEB3B",
  },
  {
    "id": "016",
    "name": "Songkran",
    "backgroundColor": "#FFEA00",
    "primaryColor": "#00C853",
    "secondaryColor": "#4A148C",
    "accentColor1": "#FF6F00",
  },
];



module.exports = { theme };
