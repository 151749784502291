export const translationEn = {
  "addContact": {
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill only 5 numbers",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "documentHeader": "Receipt/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "othr": "N/A",
    "number": "Number",
    "tel": "Tel",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1":"This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Tax Invoice No.",
    "referDate": "Refer Receipt/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "sentData": "Sent Information",
    "confirmSentData": "Confirm Sent Information",
    "next": "Next",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "taxIdError": "Please fill 13 digit number",
    "updateSuccess": "Updated Successfully!",
    "updateFail": "Updated Fail!",
    "namePlaceHolder": "",
    "addressForCreate": "Address",
    "email": "Email",
    "contactForCustomer": "Contact",
    "registerVat": "Register VAT",
    "registerVatYes": "Register VAT",
    "registerVatNo": "Not register VAT",
    "saveSeparator": "Click below button to save",
    "save": "Save",
    "saving": "Saving...",
    "notDisplay": "None",
    "alreadyHaveTaxId": "This Tax ID is already used",
    "alreadyHaveContact": "This contact is already added",
    "processing": "Processing...",
    "header": "Contact Information",
    "headerDescription": "(Fill out this form for displaying in documents)",
    "companyInfo": "Company Details",
    "taxNumberType": "Type",
    "txid": "Tax ID (Juristic Person) ",
    "nidn": "ID Number (Ordinary Person)",
    "ccpt": "Passport Number",
    "english": "English",
    "on": "On",
    "off": "Off",
    "taxId": "Tax ID",
    "branchDisplay": "Branch",
    "branchNumberDesc": "ex. 00001",
    "search": "Search",
    "companyName": "Name",
    "addressHeader": "Address Details",
    "addressStructure": "Address Structure",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetPrefix": "Street Prefix",
    "streetName": "Street",
    "provincePrefix": "Province Prefix",
    "province": "Province",
    "districtPrefix": "District Prefix",
    "district": "District (Choose Province first) ",
    "districtPlus": " ",
    "subDistrictPrefix": "Subdistrict Prefix",
    "subDistrict": "Subdistrict (Choose District first)",
    "postcode": "Postcode",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "country": "Country",
    "contactHeader": "Contact Details",
    "phone": "Tel.",
    "website": "Website",
    "fax": "Fax",
    "contactHeader2": "Other Contact Details",
    "contactName": "Contact Name",
    "contactNickName": "Contact Nickname",
    "contactEmail": "Contact Email",
    "contactPhone": "Contact Tel.",
    "contactDepartment": "Department",
    "contactPosition": "Position",
    "paymentHeader": "Payment Details",
    "accountName": "Account Name",
    "bankName": "Bank",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account Number",
    "paymentNote": "Payment Note",
    "addBank": "Add Bank",
    "back": "Back",
    "getErrorMessageTaxIdZeroOnly": "Invalid value",
    "firstStep": "Step 1: Fill information.",
    "secendStep": "Step 2: Verify the information.",
    "pleaseCheck": "Please review the information for accuracy and completeness.",
    "thirdStep": "Step 3: Data has been successfully submitted.",
    "done": "Done",
    "ninText": "National identification number",
    "passportText": "Passport number",
    "tinText": "Tax identification number",
    "en": "EN",
    "success": "Success !",
    "enterClient": "Fill Client Information",
    "fillDataRequest": "Request a tax invoice",
    "fillDataRequest2": "",
    "onlyName": "name",
    "fullRequest": "",
    "fullRequest2": "Request Tax Invoice Form",
    "pleaseSelectBranch": "Please select a branch",
    "pleaseEnterYourEmail": " Please enter your email",
    "pleaseCheckYourEmail": "Please check your email.",
    "pleaseSelectTaxPayerType": "Please select taxpayer type.",
    "pleaseEnterYourName": "Please enter your name.",
    "pleaseEnterTheNumber": "Please enter the number.",
    "pleaseEnterTheAddress": "Please enter the address.",
    "pleaseSelectaProvince": "Please select a province.",
    "pleaseSelectaDistrict": "Please select a district",
    "pleaseSelectaSubdistrict": "Please select a sub-district.",
    "headOffice": "Head office",
    "sendDataViaEmail": "Send data via email",
    "branchNumber": "Branch Number",
    "personalInformation": "Personal data",
    "branch": "Branch",
    "name": "Name",
    "contacts": "contacts",
    "description": "Note",
    "sentDataInprocess": "Sending data",
    "dataNotSent": "Sorry, data not sent.",
    "pleaseCheckData": "Please check the data",
    "retrySentData": "Retry sending data",
    "formNotFound": "Form not found",
    "checkUrl": "Check URL",
    "retryScan": "Retry scanning QR code",
    "cantFillForm": "Form request right now can't fill",
    "formConditionFooter":"The full tax invoice request form is activated by the service user on Leceipt software. The information you provide will be sent to this service user. Leceipt is not responsible for the privacy or security practices of the customer, including those of this service user.",
    "privacyPolicy": "Privacy Policy",
    "termsOfDescription":"This full tax invoice request form is activated by the user of the Leceipt software. The information provided by the customer will be sent to this account, and the user who activates the form will be considered the data collector and responsible for using the personal information. The user activating this form must inform the form fillers about the privacy policy. Leceipt, as the software provider, will not use the personal information and will not be responsible for the privacy or security practices of the customer.",
    "acknowledge":"Acknowledge privacy policy",
    "warningUser":"The user of the Leceipt software who has activated this form has not provided a privacy statement regarding how your response data will be used. Do not provide personal or sensitive information",
    "acknowledgePrivacy":"Agree",
    "getStarted":"Get Started",
    "termOfService":"Terms of service.",
    "backToHomePage":"Back to home page",
    "latestUpdate":"Latest Update"
  },
};
