import React, { useState } from "react";
import MultiStepFormforUnSuccess from "./MultiStepFormforUnSuccess";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  Switch,
  makeStyles,
  tokens,
  shorthands,
  typographyStyles,
  Text,
  Button,
} from "@fluentui/react-components";
import { ErrorCircleRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("20px"),
    maxWidth: "400px",
    "> div": {
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      // need padding to see the background color for filled variants
      ...shorthands.padding("5px", "20px", "10px"),
    },
  },
  base: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalXXS,
    marginTop: tokens.spacingVerticalMNudge,
    ...shorthands.padding(tokens.spacingHorizontalMNudge),
  },
  fieldLong: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  title: typographyStyles.title3,
  languagechange: {
    ...typographyStyles.subtitle2,
    color: "var(--secondary-color)",
  },

});

const UnSuccess = ({
  step,
  setStep,
  urlID,

  isStepCompleted,
}) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const history = useHistory();
  const formData = history.location.state?.formData || null;
  useState(() => {
    setStep(3);
  }, []);

  return (
    <div className="responsive-element-summary">
      <div style={{ width: "100%", margin: "0 auto", paddingTop: "20px" }}>
        <div className="header-part">
          <div className="header-topic">
            <div className="header-topic-word">
              <Text as="h1" block className={styles.title}></Text>
            </div>

            <div className="header-topic-switch">
              <Switch
                styles={{
                  alignSelf: "center",
                  color: "var(--primary-color)",
                }}
                inlineLabel
                label={
                  <Text className={styles.languagechange}>
                    {t("addContact.en")}
                  </Text>
                }
                onText={t("addContact.on")}
                offText={t("addContact.off")}
                checked={i18n.resolvedLanguage === "en"}
                onChange={() => {
                  const newLanguage =
                    i18n.resolvedLanguage === "en" ? "th" : "en";
                  i18n.changeLanguage(newLanguage);
                }}
              />
            </div>
          </div>
          {step === 3 && (
            <MultiStepFormforUnSuccess
              step={step}
              setStep={setStep}
              isStepCompleted={isStepCompleted}
            />
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                width: 55,
                height: 55,
                borderRadius: "50%",
                border: "1px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 0, 0, 0.8)", // Red color for failure
                color: "white",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
            >
              <ErrorCircleRegular
                style={{
                  width: 55,
                  height: 55,
                }}
              />
              {/* Display error icon for unsuccessful request */}
            </div>
            <div
              style={{
                alignSelf: "center",
                fontSize: "20px",
                marginBottom: "50px",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
            >
              {t("addContact.dataNotSent")}
            </div>
            <div
              style={{
                alignSelf: "center",
                fontSize: "20px",
                marginBottom: "50px",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
            >
              {t("addContact.pleaseCheckData")} <br />{" "}
              {t("addContact.retrySentData")}
            </div>
            <div className="responsive-boxbutton">
              <Button
                appearance="secondary"
                size="large"
                style={{ marginTop: "50px", width: "100px" }}
                onClick={() => {
                  history.push({
                    pathname: `/summary-information/${urlID}`,
                    state: {
                      formData,
                      urlID,
                    },
                  });

                  setStep(2);
                  isStepCompleted(2);
                }}
              >
                {t("addContact.back")}
              </Button>

              <br />
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnSuccess;
