import React from "react";
import { Spinner } from "@fluentui/react-components";

const FullScreenSpinner = () => {
  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: background overlay color
  };
  return (
    <div style={overlayStyles}>
      <Spinner size="extra-large" label="Loading..." />
    </div>
  );
};

export default FullScreenSpinner;
