import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  makeStyles,
  Dropdown,
  useId,
  Option,
} from "@fluentui/react-components";

import { GlobeErrorFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  listBox: {
    minWidth: "50px",
  },
});

const WrongUrl = ({ setStep }) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  useState(() => {
    setStep(3);
  }, []);

  const dropdownLanguage = useId("dropdown-default");

  const optionsLanguage = ["TH", "EN"];
  const defaultValue = optionsLanguage[0];

  function languageChange(lang) {
    const language = lang.toLowerCase();
    console.log("ภาษาเปลี่ยน", language);
    const newLanguage = language === "th" ? "th" : "en";
    i18n.changeLanguage(newLanguage);
  }

  return (
    <div className="responsive-element">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 auto",
          paddingTop: "20px",
          alignItems: "center",
        }}
      >
        {/* Grey background overlay while loading */}

        <div className="header-part">
          {/* {step === 3 && (
            <MultiStepFormforUnSuccess
              step={step}
              setStep={setStep}
              isStepCompleted={isStepCompleted}
            />
          )} */}

          {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "12px",
            textAlign: "center",
          }}
        >
          <Label style={{ fontSize: "0.9rem", textAlign: "left" }}>
            {" "}
            {t("addContact.headerDescription")}
          </Label>
        </div> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                marginLeft: "10px",
                fontSize: "25px",
                marginTop: "50px",
                position: "relative",
              }}
            >
              <div className="header-wrong-url-switch">
                <Dropdown
                  aria-labelledby={dropdownLanguage}
                  placeholder="เปลี่ยนภาษา"
                  defaultSelectedOptions={[defaultValue]}
                  defaultValue={defaultValue}
                  dropdownWidthAuto={true}
                  listBox={{ className: styles.listBox }}
                  style={{
                    minWidth: "50px",
                    position: "absolute",
                  }}

                  // styles={{ calloutWidth: 50, calloutMaxWidth: 50 }}
                >
                  {optionsLanguage.map((option) => (
                    <Option key={option} onClick={() => languageChange(option)}>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>{" "}
            <div
              style={{
                width: 55,
                height: 55,
                borderRadius: "50%",
                border: "1px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 0, 0, 0.8)", // Red color for failure
                color: "white",
                marginBottom: "1rem",
                marginTop: "2rem",
              }}
            >
              <GlobeErrorFilled
                style={{
                  width: 55,
                  height: 55,
                }}
              />
              {/* Display error icon for unsuccessful request */}
            </div>
            <div
              style={{
                alignSelf: "center",
                fontSize: "20px",
                marginBottom: "50px",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
            >
              {t("addContact.formNotFound")}
            </div>
            <div
              style={{
                alignSelf: "center",
                fontSize: "20px",
                marginBottom: "50px",
                color: "var(--primary-color)",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("addContact.checkUrl")} <br /> {t("addContact.retryScan")}
            </div>
            {/* <div className="responsive-boxbutton">
              <Button
                appearance="secondary"
                style={{ marginTop: "50px", width: "100px" }}
                onClick={() => {
                  history.push({
                    pathname: `/${urlID}`,
                  });

                  setStep(1);
                  isStepCompleted(1);
                }}
              >
                กลับ
              </Button>

              <br />
              <br />

              <br />
              <br />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WrongUrl;
