import React from "react";

import { Stack } from "@fluentui/react";

import { useTranslation } from "react-i18next";
import {
  ProgressBar,
  makeStyles,
  shorthands,
  Label,
  typographyStyles,
  Text,
  Spinner,
  useId,
  Option,
  Dropdown,
  Persona
} from "@fluentui/react-components";
import { useState } from "react";

const useStyles = makeStyles({
  container: {
    ...shorthands.margin("20px", "0px"),
  },
  root: {
    fontSize: "21px",
    fontWeight: "bold",
    color: "var(--accent-color-1)",
  },
  underRoot: {
    fontSize: "15px",
    color: "var(--primary-color)",
  },
  title: {
    ...typographyStyles.title3,
    color: "var(--primary-color)",
    textAlign: "center",
  },
  listBox: {
    minWidth: "50px",
  },
});

const MultiStepForm = ({
  step: initialStep,
  isStepCompleted,
  savedData,
  loading,
  currentLanguage,
  setCurrentLanguage
}) => {
  const totalSteps = 3;
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  const dropdownLanguage = useId("dropdown-default");

 const optionsLanguage = [
    {
      "Abb": "TH",
      "Full": "ภาษาไทย"
    },
    {
      "Abb": "EN",
      "Full": "English"
    }
  ];

  const [selectedOption, setSelectedOption] = useState(optionsLanguage[0]);

function languagechange(lang) {
  const language = lang.Abb.toLowerCase();
  console.log("ภาษาเปลี่ยน", language);

  // Determine the new language code based on the selected option's abbreviation
  const newLanguage = language === "th" ? "th" : "en";

  // Change the language using the i18n library
  i18n.changeLanguage(newLanguage);
  const upperCaseLanguage = newLanguage.toLocaleUpperCase()
  // Update the selected option to keep the abbreviation displayed outside the dropdown
  setCurrentLanguage(upperCaseLanguage)
  setSelectedOption(lang);
}

  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        paddingTop: "20px",
      }}
    >
      {/* Grey background overlay while loading */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Spinner component */}
          <Spinner size="large" label="Loading..." />
        </div>
      )}
      <div style={{ width: "100%", minHeight: "95px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "absolute",

            top: "3px",
          }}
        >
          <Text as="h1" block className={styles.title}>
            {t("addContact.fullRequest")}
            <br />
            {t("addContact.fullRequest2")}
          </Text>
        </div>
        <div className="switch-language-postion">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
 <Dropdown
      aria-labelledby={dropdownLanguage}
      placeholder={currentLanguage} // Ensure Abb is used here
      dropdownWidthAuto={true}
      listBox={{ className: styles.listBox }}
      style={{
        minWidth: "50px",
        position: "absolute",
      }}
      
    >
      {optionsLanguage.map((option) => (
        <Option key={option.Abb} onClick={() => languagechange(option)}>
          
          <Text>{option.Full}</Text>
          
         {/* Show Full text inside the dropdown */}
        </Option>
      ))}
    </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <Stack horizontal horizontalAlign="center">
        {[...Array(totalSteps).keys()].map((index) => (
          <div key={index} style={{ marginRight: "5px", marginLeft: "5px" }}>
            <div
              style={{
                width: 35,
                height: 35,
                borderRadius: "50%",
                border: "1px solid #000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "20px",
                background:
                  initialStep === index + 1
                    ? "var(--accent-color-1)"
                    : "var(--primary-color)",
                color:
                  initialStep === index + 1
                    ? "var(--secondary-color)"
                    : isStepCompleted(index + 1)
                    ? "var(--secondary-color)"
                    : "var(--background-color)",
              }}
            >
              {index + 1}
            </div>
          </div>
        ))}
      </Stack>
      <ProgressBar
        className={styles.container}
        shape="square"
        thickness="large"
        value={
          initialStep === 1
            ? 0.3
            : initialStep === 2
            ? 0.7
            : initialStep === 3
            ? 1.0
            : 0
        }
      />

      {initialStep === 1 && (
        <>
          {savedData && (
            <div
              style={{ color: "var(--secondary-color)" }}
              dangerouslySetInnerHTML={{ __html: savedData.previewText }}
            >
              
            </div>
          )}

          <Label className={styles.root}> {t("addContact.firstStep")}</Label>

          <br />
          <Label className={styles.underRoot}>
            {t("addContact.headerDescription")}
          </Label>
        </>
      )}
      {initialStep === 2 && (
        <>
          <Label className={styles.root}> {t("addContact.secendStep")}</Label>
          <br />
          <Label className={styles.underRoot}>
            {t("addContact.pleaseCheck")}
          </Label>
        </>
      )}
      {initialStep === 3 && (
        <>
          <Label className={styles.root}> {t("addContact.thirdStep")}</Label>
          <br />
        </>
      )}
    </div>
  );
};

export default MultiStepForm;
