import React from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  makeStyles,
  typographyStyles,
  Text,
  useId,
  Option,
  Dropdown,
} from "@fluentui/react-components";

import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  title: typographyStyles.title3,
});

const CloseFormPage = ({ savedData, setUrlID }) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const { urlget } = useParams();

  useEffect(() => {
    if (urlget) {
      setUrlID(urlget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    console.log("saveData",savedData);
    
  }, [urlget]);

  const dropdownLanguage = useId("dropdown-default");

  const optionsLanguage = ["TH", "EN"];
  const defaultValue = optionsLanguage[0];

  function languageChange(lang) {
    const language = lang.toLowerCase();
    console.log("ภาษาเปลี่ยน", language);
    const newLanguage = language === "th" ? "th" : "en";
    i18n.changeLanguage(newLanguage);
  }


  
  return (
    <div className="responsive-element-summary">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 auto",
          paddingTop: "20px",
          alignItems: "center",
        }}
      >
        {/* Grey background overlay while loading */}

        <div className="header-part">
 

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "center",
                marginLeft: "10px",
                fontSize: "25px",
                marginTop: "50px",
                position: "relative",
              }}
            >
              <Text as="h2" size="400" className={styles.title}>
                {t("addContact.cantFillForm")}
              </Text>
              <div className="header-close-form-switch">
                <Dropdown
                  aria-labelledby={dropdownLanguage}
                  placeholder="เปลี่ยนภาษา"
                  defaultSelectedOptions={[defaultValue]}
                  defaultValue={defaultValue}
                  style={{ minWidth: "50px" }}
                >
                  {optionsLanguage.map((option) => (
                    <Option key={option} onClick={() => languageChange(option)}>
                      {option}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </div>
              {savedData.previewTextThird.length > 8 && ( <div className="card-style-display-text" style={{ marginTop: "50px",backgroundColor:"none" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
             
                  <div 
                    dangerouslySetInnerHTML={{
                      __html: savedData.previewTextThird,
                    }}
                  ></div>
              
              </div>
            </div>
              )}
            <div className="responsive-boxbutton">
              {/* <Button
                appearance="secondary"
                style={{ marginTop: "50px" }}
                onClick={() => {
                  history.push({
                    pathname: "/add-contacts",
                  });

                  setStep(1);
                }}
              >
                กลับไปที่หน้าแรก
              </Button> */}

              <br />
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseFormPage;
